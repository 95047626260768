.flex {
    display: flex;
}

.outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
}

.font-bold {
    font-weight: 700;
}

.text-base {
    font-size: 1rem;
    line-height: 1.5rem;
}

.py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
}

.px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.p-7 {
    padding: 1.75rem;
}

.bg-black-light {
    background-color: #242526;
}

.rounded {
    border-radius: 20px;
}

.rounded-mini {
    border-radius: 10px;
}

.justify-center {
    justify-content: center;
}

.min-w-140px {
    min-width: 140px;
}


.space-x-10>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}


.bg-main-blue {
    background-color: #02291e;
}

.rounded {
    border-radius: 20px;
}


.w-full {
    width: 100%;
}


.mtx-5 {
    margin-top: 55px !important
}

.min-h-340px {
    min-height: 340px;
}


.col-span-4 {
    grid-column: span 4/span 4;
}


.relative {
    position: relative;
}


.p-7\.5 {
    padding: 1.875rem;
}

.xl\:w-full {
    width: 50%;
    margin-top: 20px;
}

.p-5 {
    padding: 1.25rem;
}

.overflow-hidden {
    overflow: hidden;
}

.flex-col {
    flex-direction: column;
}

.z-10 {
    z-index: 10;
}

.justify-between {
    justify-content: space-between;
}

.pb-10 {
    padding-bottom: 2.5rem;
}

.justify-start {
    justify-content: flex-start;
}

.items-start {
    align-items: flex-start;
}

.flex-1 {
    flex: 1 1 0%;
}

.items-center {
    align-items: center;
}

.text-white-300 {
    color: hsla(0,0%,100%,.3);
}

.font-normal {
    font-weight: 400;
}

.text-two-half {
    font-size: 2.5rem;
}

.w-6 {
    width: 1.5rem;
}

.h-6 {
    height: 1.5rem;
}

.mr-2\.5 {
    margin-right: .625rem;
}

.bg-opacity-50 {
    --tw-bg-opacity: 0.5;
}

.first\:mt-0:first-child {
    margin-top: 0;
}

.justify-evenly {
    justify-content: space-evenly;
}

.text-main-blue {
    color: rgb(29 204 74);
    font-size: 20px
}

.text-main-purple {
    color: rgb(102 29 204);
    font-size: 20px
}


.bg-hover-main-blue {
    --tw-bg-opacity: 1;
    background-color: rgb(65 71 78)
}


.bg-hover-main-yellow {
    --tw-bg-opacity: 1;
    background-color: #FFC107;
}

.rounded-full {
    border-radius: 9999px;
}

.w-7 {
    width: 1.75rem;
}

.h-7 {
    height: 1.75rem;
}

.mt-7\.5 {
    margin-top: 1.875rem;
}

.h-20 {
    height: 5rem;
}


.w-20 {
    width: 5rem;
}

.ml-2\.5 {
    margin-left: .625rem;
}

.justify-end {
    justify-content: flex-end;
}

.xl\:middle {
    display: none;
}

@media (max-width: 767px) {
    .sm\:min-h-290px {
        min-height: 290px;
    }

    .sm\:p-5 {
        padding: 1.25rem;
    }

    .sm\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .sm\:mb-10 {
        margin-bottom: 2.5rem;
    }

    .sm\:w-15 {
        width: 2.75rem;
    }

    .sm\:h-15 {
        height: 2.5rem;
    }

    .xl\:w-full {
        width: 100%;
        margin-top: 20px;
    }

    .sm\:mt-5 {
        margin-top: 1.25rem;
    }

    .sm\:w-10 {
        width: 2.5rem;
    }

    .sm\:h-10 {
        height: 2.5rem;
    }

    .sm\:text-white-500 {
        color: hsla(0,0%,100%,.5);
    }

    .sm\:text-sm {
        font-size: .875rem;
        line-height: 1.25rem;
    }

    .sm\:space-y-7\.5>:not([hidden])~:not([hidden]) {
        --tw-space-y-reverse: 0;
        margin-top: calc(1.875rem * calc(1 - var(--tw-space-y-reverse)));
        margin-bottom: calc(1.875rem * var(--tw-space-y-reverse));
    }

    .sm\:px-5 {
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }

    .sm\:text-xl {
        font-size: 1.25rem;
        line-height: 1.75rem;
    }

    .xl\:hidden {
        display: none;
    }

    .xl\:middle {
        display: block;
    }


    .xl\:space-x-0>:not([hidden])~:not([hidden]) {
        --tw-space-x-reverse: 0;
        margin-right: calc(0px * var(--tw-space-x-reverse));
        margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
    }

    .sm\:min-h-290px {
        min-height: 290px;
    }

    .sm\:p-5 {
        padding: 1.25rem;
    }

    .sm\:w-10 {
        width: 2.5rem;
    }

    .sm\:h-10 {
        height: 2.5rem;
    }
}